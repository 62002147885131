/* General popup styling */
.notification-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center; 
    align-items: center; 
    opacity: 0;
    pointer-events: none; 
    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.notification-popup.show {
    opacity: 1; 
    pointer-events: auto; 
}

/* Overlay styling */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(62, 35, 35, 0.7); 
    z-index: 900; 
}

/* Dialog container styling */
.dialog-container {
    background-color: #333030; 
    color: white; 
    border-radius: 10px; 
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); 
    padding: 30px;
    max-width: 600px; 
    width: 80%; 
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
    position: relative; 
    z-index: 1100; 
    animation: fadeIn 0.3s; 
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9); 
    }
    to {
        opacity: 1;
        transform: scale(1); 
    }
}

/* Content alignment */
.dialog-content {
    text-align: center; 
}

/* Notification details styling */
.notification-details {
    text-align: left; 
}

/* Improved header styling for title */
.notification-details h1 {
    margin: 10px 0; 
    color: rgba(255, 255, 255, 1);


}

.notification-details h3 {
    font-size: 18px; 
    margin: 5px 0; 
    color: rgba(255, 255, 255, 1);
}

.notification-details h2 {
    font-weight: bold;
    margin: 20px 0;
    color: #ffcc00; 
}

.notification-details p {
    font-size: 16px;
    color: #e0e0e0; 
    margin: 5px 0;
}

.notification-details h2  {
    font-size: 28px; 
    font-weight: bold; 
    margin: 20px 0;
    color: #ffcc00; 
}

.notification-details p {
    font-size: 16px; 
    color: #e0e0e0; 
    margin: 5px 0; 
}


.button-container {
    display: flex;
    justify-content: flex-end; 
    margin-top: auto; 
}


.dialog-button {
    margin-left: 10px; 
    padding: 12px 20px; 
    border: none;
    border-radius: 8px; 
    cursor: pointer;
    font-size: 18px; 
    transition: background-color 0.3s, transform 0.2s; 
}

/* Primary button styling */
.dialog-button.primary {
    background-color: #007bff; /* Blue button */
    color: white;
}

.dialog-button.primary:hover {
    background-color: #0056b3; 
    transform: translateY(-2px); 
}

/* Close button styling */
.cancel-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border-radius: 8px; 
    cursor: pointer;
    font-size: 20px; 
    color: #e6e1e7; 
}

.cancel-button:hover {
    background-color: rgba(255, 255, 255, 0.1); 
}
